import '../helpers/helpers';

import * as slickSliders from '../functions/slickSliders';
import * as googleMap from '../functions/googleMap';
import * as scrollEvent from '../eventHandlers/scrollEvent';

import * as AOS from 'aos';

document.addEventListener('DOMContentLoaded', function(){

	function toggleOverlay() {
		var overlay = $('.overlay');

		if(overlay.hasClass('open')) {
			overlay.removeClass('open');
			overlay.addClass('close');

			setTimeout(function(){
				overlay.removeClass('close');
			}, 250);
		} else if(!overlay.hasClass('close')) {
			overlay.addClass('open');
		}
	}

	var $lightgallery = $('.lightgallery'),
		$map = document.getElementById('map'),
		$mainSlider = document.querySelectorAll('.main-slider');

	if($mainSlider){
		slickSliders.init();
	}

	scrollEvent.init();

	$('.trigger-btn, .overlay-close').on('click', toggleOverlay);

	$(document).find('.trigger-btn--main').on('click', function(){
		if($(this).hasClass('hamburger--spin')) {
			$(this).removeClass('hamburger--spin').addClass('hamburger--arrow');

			$('.main-navigation').removeClass('active');
		} else {
			$(this).addClass('hamburger--spin').removeClass('hamburger--arrow');

			$('.main-navigation').addClass('active');
		}
	});

	if($('.col-list').length) {
		$('.col-list').find('ul').on('click', 'li', function(e){
			let $self = $(this);

			if($self.children().is('ul')) {
				e.preventDefault();
				e.stopPropagation();

				$self.toggleClass('active');
				
				$self.children('ul').slideToggle();
			}
		});
	}

	if($lightgallery.length) {
		$(".lightgallery").lightGallery({
			selector: 'this' 
		});

		// Thumbnails
		// $(".small-gallery").lightGallery({
		// 	thumbnail:true
		// });
	}

	if($map) {
		googleMap.init();
	}

	[].forEach.call(document.querySelectorAll('img[data-src]'), function(img) {
	  img.setAttribute('src', img.getAttribute('data-src'));
	  img.onload = function() {
	    img.removeAttribute('data-src');
	  };
	});

	$('.btn--contact').on('click', function(){
		$('.popup-hld--contact').addClass('js-show');
	});

	if($('.popup-hld--startpage')) {
		$('.popup-hld--startpage').addClass('js-show');
	}

	$('.close-btn, .popup-blank').on('click', function(){
		$('.popup-hld').removeClass('js-show');
	});

});

// $(window).on('load', function() {
	
// 	if(!Modernizr.csspointerevents && !Modernizr.touchevents) {
// 		var mySkrollr = skrollr.init({
// 			edgeStrategy: 'set',
// 			smoothScrolling: false,
// 			forceHeight: false
// 		});
// 	}

// });

AOS.init({
	disable: 'mobile',
	easing: 'ease',
	offset: 20,
	duration: 1000
});