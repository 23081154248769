export function init() {

	var $mainSlider = document.querySelectorAll('.main-slider'),
		$blogSlider = document.querySelectorAll('.blog-slider');

	if($mainSlider) {
		$('.main-slider').on('init', function(event, slick, currentSlide, nextSlide){
			var $activeSlide = $('.main-slider .slick-track .slick-active');

			$activeSlide.find('.slider-content-inner').removeClass('hide-elem').addClass('animate-init');
		});

		$('.main-slider').on('afterChange', function(event, slick, currentSlide, nextSlide){
			var $activeSlide = $('.main-slider .slick-track .slick-active');

			$activeSlide.prev().find('.slider-content-inner').removeClass('animate-init').addClass('hide-elem');

			$activeSlide.next().find('.slider-content-inner').removeClass('animate-init').addClass('hide-elem');

			$activeSlide.find('.slider-content-inner').addClass('animate-init').removeClass('hide-elem');
		});

		$('.main-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 1,
			autoplay: true,
			autoplaySpeed: 4000,
			fade: true,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: false,
			pauseOnFocus: false,
			pauseOnHover: false, 
			arrows: true,
			prevArrow: '<span class="slick-prev"><i class="icon-left-arrow"></i></span>',
			nextArrow: '<span class="slick-next"><i class="icon-right-arrow"></i></span>'
		});
	}

	if($blogSlider) {
		$('.blog-slider').slick({
			infinite: true,
			speed: 1000,
			slidesToShow: 4,
			slidesToScroll: 2,
			useTransform: true,
			cssEase: 'cubic-bezier(0.75,0.25,0.25,1)',
			dots: true,
			arrows: false
		});
	}
}