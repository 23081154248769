export function init() {

	var $map = document.getElementById('map');

	window.callInitGoogleOptions = function() {
		initGoogleOptions();
	};

	var loadAsyncScript = function() {
		var s = document.createElement('script');

		s.src = '//maps.googleapis.com/maps/api/js?sensor=false&key=AIzaSyDc0vCJDTFRC727aQe8KtdgGaMagRRO5kI&callback=callInitGoogleOptions';
		document.body.appendChild(s);
	};

	$(window).bind('load scroll', function(){
		var mapCanvasOffset = $('#map').offset().top - $(window).scrollTop() - 1000;

		if($(window).scrollTop() > mapCanvasOffset && !$('body').is('.map-loaded')) {
			$(document).trigger('load-map');
		}
	});
	
	$(document).on('load-map', function(){
		$('body').addClass('map-loaded');
		
		loadAsyncScript();
	});

	function initGoogleOptions() {
		var myLatLng = {lat: 50.2545542, lng: 19.0200287},
			myOptions = {
	        	zoom: 14,
	        	center: myLatLng,
	        	scrollwheel: false,
                scaleControl: false,
                disableDefaultUI: false,
                styles: [{featureType:"landscape",stylers:[{saturation:-100},{lightness:65},{visibility:"on"}]},{featureType:"poi",stylers:[{saturation:-100},{lightness:51},{visibility:"simplified"}]},{featureType:"road.highway",stylers:[{saturation:-100},{visibility:"simplified"}]},{featureType:"road.arterial",stylers:[{saturation:-100},{lightness:30},{visibility:"on"}]},{featureType:"road.local",stylers:[{saturation:-100},{lightness:40},{visibility:"on"}]},{featureType:"transit",stylers:[{saturation:-100},{visibility:"simplified"}]},{featureType:"administrative.province",stylers:[{visibility:"off"}]},{featureType:"administrative.locality",stylers:[{visibility:"off"}]},{featureType:"administrative.neighborhood",stylers:[{visibility:"on"}]},{featureType:"water",elementType:"labels",stylers:[{visibility:"on"},{lightness:-25},{saturation:-100}]},{featureType:"water",elementType:"geometry",stylers:[{hue:"#ffff00"},{lightness:-25},{saturation:-97}]}],
                mapTypeId: google.maps.MapTypeId.ROADMAP
	        },
	        map = new google.maps.Map($map,myOptions),
	        marker = new google.maps.Marker({
	            map: map, 
	            position: myLatLng
	        });

	  	marker.setMap(map);

        var infowindow = new google.maps.InfoWindow({
            content: '<span class="map-txt"><strong>Law First<br/>Irena Pacholewska-Urgacz<br/> Kancelaria Radcy Prawnego</strong><br/>ul. Przemysłowa 10 lok. 402<br/>+48 664-769-349<br/>kancelaria@lawfirst.pl<br/><a href="https://www.google.com/maps/place/Law+First+Irena+Pacholewska-Urgacz+Kancelaria+Radcy+Prawnego/@50.2543956,19.0332195,18.75z/data=!4m12!1m6!3m5!1s0x4716cffdd6480e7f:0x59d676582d654e53!2sLaw+First+Irena+Pacholewska-Urgacz+Kancelaria+Radcy+Prawnego!8m2!3d50.254468!4d19.033271!3m4!1s0x4716cffdd6480e7f:0x59d676582d654e53!8m2!3d50.254468!4d19.033271" target="_blank">Zobacz w Mapach Google</a></span>'
        });
                    
        google.maps.event.addListener(marker, "click", function() {
          infowindow.open(map,marker);
        });
	}
	
}